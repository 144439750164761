<template>
  <div style="overflow:hidden">
    <div class="caseimg">
      <img :src="bannerimg" alt="">
    </div>
    <!-- 数字文娱 -->

    <!-- <div class="cases">
        <div class="product_title">
          <div class="product_tit">
            <div class="tit1">数字文娱</div>
            <div class="tit2"></div>
          </div>
        </div>
        <div class="caselist">
          <ul class="caselistul">
            <li v-for="(item, index) in casearr[currpage - 1]" :key="index">
              <div class="caseimg">
                <img :src="item.articleCover" alt="" />
              </div>
              <div class="casetit">{{ item.articleTitle }}</div>
            </li>
          </ul>
        </div>
        <div class="pagination">
          <div class="prevbtn" @click="prevpage()"></div>
          <div class="page">
            <ul>
              <li
                v-for="(item, index) in pages"
                :key="index"
                :class="index + 1 === currpage ? 'hover' : ''"
                @click="choosepage(index)"
              ></li>
            </ul>
          </div>
          <div class="nextbtn" @click="nextpage()"></div>
        </div>
      </div>
       <div class="fgline"></div>
    <div class="xuni">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">虚拟仿真</div>
          <div class="tit2"></div>
        </div>
      </div>
      <div class="caselist">
        <ul class="caselistul">
          <li v-for="(item,index) in xncasearr[xncurrpage-1]" :key="index">
            <div class="caseimg">
              <img
                :src="item.articleCover"
                alt=""
              />
            </div>
            <div class="casetit">{{item.articleTitle}}</div>
          </li>
          
        </ul>
      </div>
      <div class="pagination">
        <div class="prevbtn" @click="xnprevpage()"></div>
        <div class="page">
          <ul>
            <li
              v-for="(item, index) in xnpages"
              :key="index"
              :class="index+1 === xncurrpage ? 'hover':''"
              @click="xnchoosepage(index)"
            ></li>
          </ul>
        </div>
        <div class="nextbtn" @click="xnnextpage()"></div>
      </div>
    </div>
    <div class="fgline"></div>
    
      <div class="yundong">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">运动捕捉</div>
          <div class="tit2"></div>
        </div>
      </div>
      <div class="caselist">
        <ul class="caselistul">
          <li v-for="(item,index) in ydcasearr[ydcurrpage-1]" :key="index">
            <div class="caseimg">
              <img
                :src="item.articleCover"
                alt=""
              />
            </div>
            <div class="casetit">{{item.articleTitle}}</div>
          </li>
          
        </ul>
      </div>
      <div class="pagination">
        <div class="prevbtn" @click="ydprevpage()"></div>
        <div class="page">
          <ul>
            <li
              v-for="(item, index) in ydpages"
              :key="index"
              :class="index+1 === ydcurrpage ? 'hover':''"
              @click="ydchoosepage(index)"
            ></li>
          </ul>
        </div>
        <div class="nextbtn" @click="ydnextpage()"></div>
      </div>
    </div> -->
    <!-- 数字文娱 -->
    <div class="sport">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">数字文娱</div>
          <div class="tit2"></div>
        </div>
      </div>

      <div class="sportswiper">
        <swiper ref="numberSwiper" :options="swiperOption1" id="myswiper">
          <swiper-slide v-for="(item,index) in casearr" :key="index">
            <div class="swiper_con">
              <img :src="item.articleCover" alt="" />
              <span>{{ item.articleTitle }}</span>
            </div>
          </swiper-slide>

          <div class="swiper-pagination my_bullet" slot="pagination"></div>
          <!-- <div class="swiper-button-prev" slot="button-prev"><img  src="../assets/images/01首页/上一页1.png" alt=""></div>
        <div class="swiper-button-next" slot="button-next"><img src="../assets/images/01首页/下一页1.png" alt=""></div> -->
        </swiper>

        <div class="swiper-button-prev-diy" @click="numberPrevFn"></div>
        <div class="swiper-button-next-diy" @click="nmuberNextFn"></div>
      </div>
    </div>
    <!-- 虚拟仿真 -->
    <div class="sport">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">虚拟仿真</div>
          <div class="tit2"></div>
        </div>
      </div>

      <div class="sportswiper">
        <swiper ref="xnSwiper" :options="swiperOption2" id="myswiper">
          <swiper-slide v-for="(item,index) in xncasearr" :key="index">
            <div class="swiper_con">
              <img :src="item.articleCover" alt="" />
              <span>{{item.articleTitle}}</span>
            </div>
          </swiper-slide>

          <div class="swiper-pagination my_bullet" slot="pagination"></div>
          <!-- <div class="swiper-button-prev" slot="button-prev"><img  src="../assets/images/01首页/上一页1.png" alt=""></div>
        <div class="swiper-button-next" slot="button-next"><img src="../assets/images/01首页/下一页1.png" alt=""></div> -->
        </swiper>

        <div class="swiper-button-prev-diy" @click="xnPrevFn"></div>
        <div class="swiper-button-next-diy" @click="xnNextFn"></div>
      </div>
    </div>
    <!-- 运动捕捉 -->
    <div class="sport">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">运动捕捉</div>
          <div class="tit2"></div>
        </div>
      </div>
      <div class="swiper_tit">
        <!-- <ul>
          <li v-for="(item, index) in titlist" :key="index" :class="{ blue: index === currindex }" @click="addclass(index, item.articleId)">
            <div :class="`swiper_img${index + 1}`"></div>
            <span>{{ item.articleTitle }}</span>
          </li>
        </ul> -->
      </div>
      <div class="sportswiper">
        <swiper ref="mySwiper" :options="swiperOption66" id="myswiper">
          <swiper-slide v-for="(item,index) in sportlist" :key="index">
            <div class="swiper_con">
              <img :src="item.articleCover" alt="" />
              <span>{{ item.articleTitle }}</span>
            </div>
          </swiper-slide>

          <div class="swiper-pagination my_bullet" slot="pagination"></div>
          <!-- <div class="swiper-button-prev" slot="button-prev"><img  src="../assets/images/01首页/上一页1.png" alt=""></div>
        <div class="swiper-button-next" slot="button-next"><img src="../assets/images/01首页/下一页1.png" alt=""></div> -->
        </swiper>

        <div class="swiper-button-prev-diy" @click="prevFn"></div>
        <div class="swiper-button-next-diy" @click="nextFn"></div>
      </div>
    </div>

  </div>
</template>

<script>
import { getbannerdetail } from "../../src/http/api/user.js";

export default {
  data() {
    return {
      bannerimg: "",
      // 数字文娱
      casearr: [],
      // 虚拟仿真
      xncasearr: [],
      // 运动捕捉
      titlist: [],
      currindex: 2,
      sportlist: [],
      // 轮播
      swiperOption1: {
        slidesPerView: 3,
        spaceBetween: 10,
        slidesPerGroup: 3,

        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // bulletClass: "my-bullet",
          // 设置点击可切换
          clickable: true,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".sportswiper .swiper-button-next",
          prevEl: ".sportswiper .swiper-button-prev",
        },
        // 设置自动轮播
        autoplay: {
          delay: 5000, // 5秒切换一次
        },
        // 设置轮播可循环
        // loop: true,
      },
      swiperOption2: {
        slidesPerView: 3,
        spaceBetween: 10,
        slidesPerGroup: 3,

        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // bulletClass: "my-bullet",
          // 设置点击可切换
          clickable: true,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".sportswiper .swiper-button-next",
          prevEl: ".sportswiper .swiper-button-prev",
        },
        // 设置自动轮播
        autoplay: {
          delay: 5000, // 5秒切换一次
        },
        // 设置轮播可循环
        // loop: true,
      },
      swiperOption66: {
        slidesPerView: 3,
        spaceBetween: 10,
        slidesPerGroup: 3,

        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // bulletClass: "my-bullet",
          // 设置点击可切换
          clickable: true,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".sportswiper .swiper-button-next",
          prevEl: ".sportswiper .swiper-button-prev",
        },
        // 设置自动轮播
        autoplay: {
          delay: 5000, // 5秒切换一次
        },
        // 设置轮播可循环
        // loop: true,
      },
    };
  },
  mounted() {
    // 获得公司背景图
    this.getcompany();
    // 数字文娱.
    this.getEntertainment();
    // 虚拟仿真
    this.getSimulation();
    // 运动捕捉
    this.getSport();
  },
  computed: {
    // 数字文娱
    numberSwiper() {
      return this.$refs.numberSwiper.$swiper;
    },
    // 虚拟仿真
    xnSwiper() {
      return this.$refs.xnSwiper.$swiper;
    },
    // 运动捕捉
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  created() {},
  methods: {
    //获取公司简介
    async getcompany() {
      let params = {
        barId: 10,
      };
      const data = await getbannerdetail(params);
      this.bannerimg = "http://36.152.65.166:10008" + data.data.image;

      // console.log(this.bannerimg);
    },
    //获取数字文娱
    async getEntertainment() {
      let params = {
        barId: 1,
      };
      const data = await getbannerdetail(params);
      this.casearr = data.data.portalArticleList[1].children;
      this.casearr.forEach((r) => {
        r.articleCover = "http://36.152.65.166:10008" + r.articleCover;
      });
      // this.casearr = data.data.portalArticleList[1];
    },
    //数字文娱分页
    numberPrevFn() {
      this.numberSwiper.slidePrev();
    },
    nmuberNextFn() {
      this.numberSwiper.slideNext();
    },
    //获取虚拟仿真
    async getSimulation() {
      let params = {
        barId: 7,
      };
      const data = await getbannerdetail(params);
      this.xncasearr = data.data.children[0].portalArticleList;
      this.xncasearr.forEach((r) => {
        r.articleCover = "http://36.152.65.166:10008" + r.articleCover;
      });
    },
    // 虚拟仿真分页
    xnPrevFn() {
      this.xnSwiper.slidePrev();
    },
    xnNextFn() {
      this.xnSwiper.slideNext();
    },
    //获取运动捕捉标题
    async getSport() {
      let params = {
        barId: 1,
      };
      const data = await getbannerdetail(params);

      this.titlist = data.data.portalArticleList[17].children;

      this.sportlist = data.data.portalArticleList[20].children;
      this.sportlist.forEach((r) => {
        r.articleCover = "http://36.152.65.166:10008" + r.articleCover;
      });
    },

    // 运动捕捉分页
    prevFn() {
      this.swiper.slidePrev();
    },
    nextFn() {
      this.swiper.slideNext();
    },
  },
};
</script>


<style lang="scss" scoped>
.swiper-container {
  overflow: unset !important;
}
.swiper-pagination {
  position: absolute !important;
  bottom: -66px !important;
  z-index: 99999;
}
.fgline {
  height: 1px;
  background-color: #e7ebf1;
  width: 1456px;
  margin: 0 auto;
  margin-top: 67px;

  margin-bottom: 52px;
}
.xuni {
  .caselist {
    width: 1500px;
    margin: 0 auto;

    ul {
      width: 100%;
      padding: 0;
      margin-top: 9px;
      margin-bottom: 0;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      li {
        height: 378px;
        width: 460px;
        background-color: #e8eaf2;
        text-align: center;

        margin-top: 30px;

        .casetit {
          height: 78px;
          line-height: 78px;
          font-size: 20px;
        }
        .caseimg {
          width: 100%;
          height: 300px;
          box-sizing: border-box;
          border: 1px solid gray;
          z-index: 222;
          img {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border: 1px solid white;
            z-index: 1;
          }
        }
      }
      li:hover {
        cursor: pointer;
        .casetit {
          background-color: #0088ea;
          color: white;
          font-weight: bold;
        }
      }
    }
  }
  .pagination {
    width: 320px;
    height: 26px;
    margin: 0 auto;
    margin-top: 72px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    .page {
      width: 30%;
      ul {
        padding: 0;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        li {
          cursor: pointer;

          width: 10px;
          height: 10px;
          background-color: #cccccc;
          border-radius: 50%;
        }
      }
      .hover {
        background-color: #666666;
      }
    }
    .prevbtn,
    .nextbtn {
      width: 26px;
      height: 26px;
      cursor: pointer;
    }
    .prevbtn {
      background: url("~@/assets/images/01首页/上一页1.png");
      background-size: contain;
    }
    .nextbtn {
      background: url("~@/assets/images/01首页/下一页1.png");
      background-size: contain;
    }
    .prevbtn:hover {
      background: url("~@/assets/images/01首页/上一页2.png");
      background-size: contain;
    }
    .nextbtn:hover {
      background: url("~@/assets/images/01首页/下一页2.png");
      background-size: contain;
    }
  }
}
.cases {
  .caselist {
    width: 1500px;
    margin: 0 auto;

    ul {
      width: 100%;
      margin: 0;
      margin-top: 12px;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      li {
        height: 378px;
        width: 460px;
        background-color: #e8eaf2;
        text-align: center;
        margin-top: 30px;

        .casetit {
          height: 78px;
          line-height: 78px;
          font-size: 20px;
        }
        .caseimg {
          width: 100%;
          height: 300px;
          box-sizing: border-box;
          border: 1px solid gray;
          z-index: 222;
          img {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border: 1px solid white;
            z-index: 1;
          }
        }
      }
      li:hover {
        cursor: pointer;
        .casetit {
          background-color: #0088ea;
          color: white;
          font-weight: bold;
        }
      }
    }
  }
  .pagination {
    width: 320px;
    height: 26px;
    margin: 0px auto;
    margin-top: 72px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    .page {
      width: 30%;
      ul {
        padding: 0;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        li {
          cursor: pointer;

          width: 10px;
          height: 10px;
          background-color: #cccccc;
          border-radius: 50%;
        }
      }
      .hover {
        background-color: #666666;
      }
    }
    .prevbtn,
    .nextbtn {
      width: 26px;
      height: 26px;
      cursor: pointer;
    }
    .prevbtn {
      background: url("~@/assets/images/01首页/上一页1.png");
      background-size: contain;
    }
    .nextbtn {
      background: url("~@/assets/images/01首页/下一页1.png");
      background-size: contain;
    }
    .prevbtn:hover {
      background: url("~@/assets/images/01首页/上一页2.png");
      background-size: contain;
    }
    .nextbtn:hover {
      background: url("~@/assets/images/01首页/下一页2.png");
      background-size: contain;
    }
  }
}
.yundong {
  .caselist {
    width: 1500px;
    margin: 0 auto;

    ul {
      width: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      li {
        height: 378px;
        width: 460px;
        background-color: #e8eaf2;
        text-align: center;

        margin-top: 30px;

        .casetit {
          height: 78px;
          line-height: 78px;
          font-size: 20px;
        }
        .caseimg {
          width: 100%;
          height: 300px;
          box-sizing: border-box;
          border: 1px solid gray;
          z-index: 222;
          img {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border: 1px solid white;
            z-index: 1;
          }
        }
      }
      li:hover {
        cursor: pointer;
        .casetit {
          background-color: #0088ea;
          color: white;
          font-weight: bold;
        }
      }
    }
  }
  .pagination {
    height: 26px;
    width: 320px;
    margin: 0 auto;
    margin-top: 72px;
    margin-bottom: 103px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .page {
      width: 30%;
      ul {
        padding: 0;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        li {
          cursor: pointer;

          width: 10px;
          height: 10px;
          background-color: #cccccc;
          border-radius: 50%;
        }
      }
      .hover {
        background-color: #666666;
      }
    }
    .prevbtn,
    .nextbtn {
      width: 26px;
      height: 26px;
      cursor: pointer;
    }
    .prevbtn {
      background: url("~@/assets/images/01首页/上一页1.png");
      background-size: contain;
    }
    .nextbtn {
      background: url("~@/assets/images/01首页/下一页1.png");
      background-size: contain;
    }
    .prevbtn:hover {
      background: url("~@/assets/images/01首页/上一页2.png");
      background-size: contain;
    }
    .nextbtn:hover {
      background: url("~@/assets/images/01首页/下一页2.png");
      background-size: contain;
    }
  }
}
.product_title {
  width: 1456px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .product_tit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 15px;
    .tit1 {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .tit2 {
      font-size: 20px !important;
      width: 60px;
      height: 4px;
      background-color: #0088ea;
    }
  }
  p {
    font-size: 24px;
  }
}
.sport {
  height: auto;

  .sportswiper {
    width: 1450px;
    height: 500px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;

    .swiper-button-prev-diy,
    .swiper-button-next-diy {
      width: 26px;
      height: 26px;
      position: absolute;
      bottom: 50px;

      transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
    }
    .swiper-button-prev-diy {
      left: 580px;

      z-index: 9999;
      background: url("~@/assets/images/01首页/上一页1.png");
      background-size: contain;
    }
    .swiper-button-prev-diy:hover {
      cursor: pointer;
      background: url("~@/assets/images/01首页/上一页2.png");
      background-size: contain;
    }
    .swiper-button-next-diy:hover {
      cursor: pointer;
      background: url("~@/assets/images/01首页/下一页2.png");
      background-size: contain;
    }
    .swiper-button-next-diy {
      z-index: 9999;
      right: 570px;
      background: url("~@/assets/images/01首页/下一页1.png");
      background-size: contain;
    }
    .swiper_con {
      cursor: pointer;
      background-color: #e8eaf2;
      width: 460px;
      height: 385px;
      border: 2px solid #0088ea;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      img {
        width: 100%;
      }
      span {
        height: 85px;
        line-height: 85px;
        font-size: 18px;
        color: #000000;
      }
      &:hover {
        background-color: #0088ea;
      }
    }
  }
  .swiper_tit {
    width: 1456px;
    // margin: -40px auto;
    ul {
      padding: 0;
      display: flex;
      justify-content: space-between;
      margin-bottom: 57px;
      margin-top: 44px;
      li {
        cursor: pointer;
        width: 488px;
        height: 60px;
        background-color: #e5f3fd;
        color: #000000;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        div {
          margin-right: 18px;
        }
        .swiper_img1 {
          width: 30px;
          height: 20px;
          background: url("~@/assets/images/01首页/产品系统2.png") no-repeat;
        }
        .swiper_img2 {
          width: 30px;
          height: 34px;
          background: url("~@/assets/images/01首页/解决方案1.png") no-repeat;
        }
        .swiper_img3 {
          width: 30px;
          height: 30px;
          background: url("~@/assets/images/01首页/项目案例1.png") no-repeat;
        }
      }
      .blue {
        background-color: #0088ea;
        cursor: pointer;
        color: white;
        .swiper_img1 {
          background: url("~@/assets/images/01首页/产品系统1.png") no-repeat;
        }
        .swiper_img2 {
          background: url("~@/assets/images/01首页/解决方案 2.png") no-repeat;
        }
        .swiper_img3 {
          background: url("~@/assets/images/01首页/项目案例2.png") no-repeat;
        }
      }
    }
  }
}
</style>